import { PieComponent } from './widgets/pie/pie.component';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatMenuModule} from '@angular/material/menu';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { ChartsModule, ThemeService } from 'ng2-charts';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatFormFieldModule, MatOption, MatOptionModule, MatSelect, MatSelectModule } from '@angular/material';
import { MatInputModule } from '@angular/material';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCheckboxModule } from '@angular/material/checkbox';






import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { TableComponent } from './widgets/table/table.component';
import { UserAnalyticsComponent } from './user-analytics/user-analytics.component';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { OrderAnalyticsComponent } from './order-analytics/order-analytics.component';
import { CounterComponent } from './widgets/counter/counter.component';
import { NewHospitalComponent } from './new-hospital/new-hospital.component';
import { PhotoPickerDialogComponent } from './photo-picker-dialog/photo-picker-dialog.component';
import { DetailsHospitalComponent } from './details-hospital/details-hospital.component';
import { LinkProcedureDialogComponent } from './link-procedure-dialog/link-procedure-dialog.component';
import { LiveComponent } from './live/live.component';
import { NewProcedureComponent } from './new-procedure/new-procedure.component';
import { DetailsProcedureComponent } from './details-procedure/details-procedure.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    UserAnalyticsComponent,
    TableComponent,
    OrderAnalyticsComponent,
    PieComponent,
    CounterComponent,
    NewHospitalComponent,
    PhotoPickerDialogComponent,
    DetailsHospitalComponent,
    LinkProcedureDialogComponent,
    LiveComponent,
    NewProcedureComponent,
    DetailsProcedureComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    MatMenuModule,
    ReactiveFormsModule,
    MatSnackBarModule,
    BrowserAnimationsModule,
    MatTableModule,
    MatPaginatorModule,
    ChartsModule,
    MatToolbarModule,
    MatIconModule,
    MatSidenavModule,
    MatFormFieldModule,
    MatInputModule,
    MatDialogModule,
    MatCheckboxModule,
    MatSelectModule,
    MatOptionModule
],
  providers: [ThemeService],
  bootstrap: [AppComponent],
  entryComponents: [
    PhotoPickerDialogComponent,
    LinkProcedureDialogComponent
 ]
})
export class AppModule { }
