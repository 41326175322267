import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConstantsService } from '../../common/services/constants.service';
import { BehaviorSubject, Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class LoginService {


  constructor(private httpClient: HttpClient) { }

  private isLoggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  isLoggedIn$: Observable<boolean> = this.isLoggedIn.asObservable();

  public loggedIn(val: boolean) {
    this.isLoggedIn.next(val);
  }

  public otp(userDetails: UserDetails) {
    return this.httpClient.post(ConstantsService.baseEndpoint + 'login/OTP',
    userDetails, { withCredentials: true });
  }

  public login(email: string, password: string) {
    return this.httpClient.post(ConstantsService.baseEndpoint + 'login/in',
    { email, password}, { withCredentials: true });
  }

  public checkStatus() {
    return this.httpClient.get(ConstantsService.baseEndpoint + 'login/status/admin',
    { withCredentials: true } );
  }

  public logout() {
    return this.httpClient.get(ConstantsService.baseEndpoint + 'login/out',
    { withCredentials: true } );
  }




}

export class UserDetails {

  firstName: string;
  lastName: string;
  phoneNumber: string;
  gender: string;
  email: string;
  dob: string;
  password: string;
  confirmPassword: string;
  city: string;
  pincode: string;
  address: string;
  otp: string;
}
