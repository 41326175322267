export class ConstantsService {
  public static readonly tabTitle: string = 'Easy My Care';
  public static readonly title: string = 'EasyMyCare';

  // public static readonly baseEndpoint: string = 'http://localhost:8080/';


  public static readonly baseEndpoint: string = 'https://api.easymycare.com/';

}
