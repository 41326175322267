import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConstantsService } from '../../common/services/constants.service';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProcedureMetaDataService {

  constructor(private httpClient: HttpClient) { }

  public addProcedureMetaData( procedure ) {
    return this.httpClient.post(ConstantsService.baseEndpoint + 'proceduremetadata/add',
    procedure, { withCredentials: true });
  }

  public getImagesURL(folder: string) {
    return this.httpClient.get(ConstantsService.baseEndpoint + 'hospital/images/get/url?folder=' + folder,
    { withCredentials: true });
  }

}
