import { BehaviorSubject } from 'rxjs';
import { Component, OnInit, AfterViewInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import { table } from 'console';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['../widget.scss', './table.component.scss']
})
export class TableComponent implements OnInit {


  tableJson;
  widgetName;

  private _data = new BehaviorSubject<any>([]);

  @Output() sendPagination = new EventEmitter<PageEvent>();

  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;

  @Input() set data(value) {
    this._data.next(value);
  }

  get data() {
    return this._data.getValue();
  }


  displayedColumns: string[];
  dataSource;
  showHeader = false;

  constructor() {
   }

  ngOnInit() {

    this._data.subscribe(tableJson => {
      if (tableJson !== undefined) {
        this.setWidgetName(tableJson.widgetName);
        this.setTableHeader(tableJson.data);
        this.setTableRows(tableJson.data);
        this.setPagination(tableJson.count);
      }
    });

  }

  setTableHeader(tableData) {

    this.displayedColumns = [];

    if (tableData !== undefined) {
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < tableData.length; i++) {
        // tslint:disable-next-line: forin
        for (const key in tableData[i]) {
          // tslint:disable-next-line: max-line-length
          if ( key !== '_id' && key !== 'profileImage' && key !== 'passwordHash' && key !== 'sessions' && key !== 'lat' && key !== 'lon'
          // tslint:disable-next-line: max-line-length
          && key !== 'userType' && key !== 'modifyTimeMilli' && key !== 'passwordRestTimeMilli' && key !== 'password' && key !== 'deleted') {
            this.displayedColumns.push(key);
          }
        }
        break;
      }
    }

    this.showHeader = true;

  }

  setWidgetName(name) {
    this.widgetName = name;
  }

  setTableRows(tableData) {
    this.dataSource = tableData;
  }

  setPagination(count) {
    this.paginator.length = count;

    this.dataSource.paginator = this.paginator;
  }

  onPaginateChange(event?: PageEvent ) {
    this.sendPagination.next(event);
  }

  headerMapping(column) {
    switch (column) {
      case 'firstName':
      case 'userFName':
        return 'First Name';

      case 'lastName':
      case 'userLName':
        return 'Last Name';

      case 'checkoutDateMilli':
        return 'Date/Time';

      case 'procedureMetaDataName':
        return 'Procedure';

      case 'procedurePrice':
        return 'Price';

      case 'hospitalName':
        return 'Hospital Name';

      case 'hospitalCity':
        return 'Hospital City';

      case 'phoneNumber':
      case 'userPhoneNumber':
        return 'Phone Number';

      case 'gender':
      case 'userGender':
        return 'Gender';

      case 'userEmail':
        return 'Email';

      case 'city':
      case 'userCity':
        return 'City';

      case 'pinCode':
        return 'Pin Code';

      case 'address':
        return 'Address';

      case 'createdTimeMilli':
        return 'Created Time';

      default:
        return column;
    }
    }

  rowData( element, column ) {

    switch (column) {
      case 'createdTimeMilli':
      case 'checkoutDateMilli':
        return new Date(element[column]).toLocaleString();
      default:
        return element[column];
    }

  }




}
