import { Component, Inject, OnInit } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { HttpUrlEncodingCodec } from '@angular/common/http';



@Component({
  selector: 'app-photo-picker-dialog',
  templateUrl: './photo-picker-dialog.component.html',
  styleUrls: ['./photo-picker-dialog.component.scss']
})
export class PhotoPickerDialogComponent implements OnInit{

  codec = new HttpUrlEncodingCodec();
  selected: any;
  images;


constructor(public dialogRef: MatDialogRef<PhotoPickerDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData) {
              this.images = data;
            }


            ngOnInit() {
            }

            urlEncode(val) {
              return this.codec.encodeValue(val);

            }

            isActive(item) {
              return this.selected === item;
            }

            select(item) {
              this.selected = item;
            }

            add() {
              this.dialogRef.close('https://easy-my-care-public-web.s3.ap-south-1.amazonaws.com/' + this.urlEncode(this.selected) );
            }

}

export interface DialogData {
  data: [];
}
