import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConstantsService } from '../../common/services/constants.service';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProcedureService {
  constructor(private httpClient: HttpClient) { }

  public addHospital( hospital ) {
    return this.httpClient.post(ConstantsService.baseEndpoint + 'hospital/add',
    hospital, { withCredentials: true });
  }

  public linkProcedure( details ) {
    return this.httpClient.post(ConstantsService.baseEndpoint + 'procedure/add',
    details, { withCredentials: true });
  }


  public getImagesURL() {
    return this.httpClient.get(ConstantsService.baseEndpoint + 'hospital/images/get/url',
    { withCredentials: true });
  }

  public hospitalList(page: number, pageSize: number, order: string) {
    return this.httpClient.get(ConstantsService.baseEndpoint + 'hospital?page=' + page + '&pageSize=' + pageSize + '&order=' + order,
    { withCredentials: true });
  }

  public delete(id,op) {
    return this.httpClient.delete(ConstantsService.baseEndpoint + 'hospital/remove?id=' + id + '&op=' + op,
    { withCredentials: true });
  }

}

export interface ProcedureDTO {
  hospitalId: string;
  procedureMetadataId: string;
  price: number;
  description: string;
  enabled: boolean;
}
