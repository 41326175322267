import { VideoCallServiceService } from './../services/videoCallSerice/video-call-service.service';
import { SseService } from './../services/sse/sse.service';
import { AgentService } from './../services/agent/agent.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { connect, createLocalVideoTrack } from 'twilio-video';


@Component({
  selector: 'app-live',
  templateUrl: './live.component.html',
  styleUrls: ['./live.component.scss']
})
export class LiveComponent implements OnInit {


  data = new Map();

  constructor(private agentService: AgentService, private sseService: SseService,
              private videoCallServiceService: VideoCallServiceService) { }

  ngOnInit() {
    this.sseService.getServerSentEvent('notifications/platform/live/events/call/notification').subscribe(data => {

      const eventData = JSON.parse((data as any).data);

      this.data.set(eventData.userId, eventData);


    });
  }
  acceptCall(event) {
    this.videoCallServiceService.acceptCall(event.userId, event.roomId).subscribe(token => {
      console.log(token);
      this.connectCall(token, event.roomId);
  });
  }

  connectCall(token, roomId) {

    const previewContainer = document.getElementById('local-media');
    const remoteContainer = document.getElementById('remote-media');


    createLocalVideoTrack().then(track => {
      previewContainer.appendChild(track.attach());
      previewContainer.getElementsByTagName('video')[0].style.width = '100%';
      previewContainer.getElementsByTagName('video')[0].style.height = '100%';
    });

    console.log('Connecting to : ' + roomId);
    connect(token, { name: roomId }).then(room => {
      console.log(`Successfully joined a Room: ${room}`);
      console.log('room details: ' + room);
      room.on('participantConnected', participant => {
        console.log(`A remote Participant connected: ${participant}`);
        participant.on('trackSubscribed', track => {
          remoteContainer.appendChild(track.attach());
        });
      });
      room.participants.forEach(participant => {
        participant.tracks.forEach(publication => {
          if (publication.track) {
            remoteContainer.appendChild(publication.track.attach());
          }
        });
        participant.on('trackSubscribed', track => {
          remoteContainer.appendChild(track.attach());
        });
      });
    }, error => {
      console.error(`Unable to connect to Room: ${error}`);
    });
  }





  handleStatusClick(status) {

    this.agentService.changeStatus(status).subscribe(success => {
        console.log(success);
    });
  }

}

