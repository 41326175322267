import { AnalyticsService } from './../services/analytics/analytics.service';
import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';

@Component({
  selector: 'app-user-analytics',
  templateUrl: './user-analytics.component.html',
  styleUrls: ['./user-analytics.component.scss']
})
export class UserAnalyticsComponent implements OnInit {

  allUser;
  top5CityBasedonUser;

  constructor(private analyticsService: AnalyticsService) {
  }

  ngOnInit() {
    this.getAllUser(0, 5);
    this.getTopCityWhereUserBelongTo(5);
  }

  getAllUser(page,row) {
    this.analyticsService.allUsers(page, row).subscribe(
      data => {
        (data as any).widgetName = 'All EMC Users';
        this.allUser = data;
      });
  }

  getTopCityWhereUserBelongTo(row) {
    this.analyticsService.getFromT('USER', 'DESC', row, 'city').subscribe(
      data => {
        (data as any).widgetName = 'Top 5 City from user belong to';
        this.top5CityBasedonUser = data;
      });
  }


  handlePagination(event?: PageEvent) {
    this.getAllUser(event.pageIndex, event.pageSize);
  }

}
