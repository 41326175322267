import { BehaviorSubject } from 'rxjs';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-counter',
  templateUrl: './counter.component.html',
  styleUrls: ['./counter.component.scss']
})
export class CounterComponent implements OnInit {

  widgetName;
  widgetData;

  private _data = new BehaviorSubject<any>([]);

  @Input() set data(value) {
    this._data.next(value);
  }

  get data() {
    return this._data.getValue();
  }

  constructor() { }

  ngOnInit() {

    this._data.subscribe(data => {

      if (data !== undefined) {
        console.log(data);
        this.setWidgetName(data.widgetName);
        this.setWidgetData(data.data);
      }
    });
  }

  setWidgetName(name){
    this.widgetName = name;
  }

  setWidgetData(data) {

    this.widgetData = data;
  }

}
