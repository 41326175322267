import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConstantsService } from '../../common/services/constants.service';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AgentService {


  constructor(private httpClient: HttpClient) { }

  public changeStatus(status) {
    // tslint:disable-next-line: max-line-length
    return this.httpClient.get(ConstantsService.baseEndpoint + 'agent/users/' + status,
    { withCredentials: true } );

  }

}
