import { element } from 'protractor';
import { BehaviorSubject } from 'rxjs';
import { Component, OnInit, AfterViewInit, ViewChild, Input, Output, EventEmitter, Injectable } from '@angular/core';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import { table } from 'console';

@Component({
  selector: 'app-chart',
  templateUrl: './pie.component.html',
  styleUrls: ['../widget.scss', './pie.component.scss']
})

export class PieComponent implements OnInit {

  private _data = new BehaviorSubject<any>([]);

  widgetName;
  // Pie
  public pieChartLabels;
  public pieChartData;
  public pieChartType = 'pie';
  showPie = false;


  constructor() {
  }



  @Input() set data(value) {
    this._data.next(value);
  }

  get data() {
    return this._data.getValue();
  }

  // events
  public chartClicked(e: any ): void {
    // console.log(e);
  }

  public chartHovered(e: any ): void {
    // console.log(e);
  }

  ngOnInit(): void {
    this._data.subscribe(tableJson => {
      if (tableJson !== undefined) {
        this.setWidgetName(tableJson.widgetName);
        this.setWidgetDats(tableJson);
      }
    });
  }

  setWidgetName(name){
    this.widgetName = name;
  }

  setWidgetDats(data) {
    const label = [];
    const value = [];
    (data as any).forEach( element => {
      label.push(element._id);
      value.push(element.count);
    });

    this.pieChartLabels = label;
    this.pieChartData = value;
    this.showPie = true;
  }


}
