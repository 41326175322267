import { LoginService } from './services/login/login.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import {FormControl} from '@angular/forms';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'partner-easy-my-care';

  isLoggedIn;

  subscription: Subscription;

  mode = new FormControl('over');
  shouldRun = true;

  constructor(private loginService: LoginService){
  }

  ngOnInit() {
    this.subscription = this.loginService.isLoggedIn$.subscribe(isLoggedIn => {
      this.isLoggedIn =  !isLoggedIn;
    });
  }

  ngOnDestroy() {
    // tslint:disable-next-line: no-unused-expression
    this.subscription && this.subscription.unsubscribe();
  }


}
