import { element } from 'protractor';
import { AnalyticsService } from './../services/analytics/analytics.service';
import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';

@Component({
  selector: 'app-order-analytics',
  templateUrl: './order-analytics.component.html',
  styleUrls: ['./order-analytics.component.scss']
})
export class OrderAnalyticsComponent implements OnInit {

  data;

  constructor(private analyticsService: AnalyticsService) {
  }

  ngOnInit() {
    this.getData(0, 5);
  }

  getData(page,row) {
    this.analyticsService.allOrders(page, row).subscribe(
      data => {
        const flatData = [];

        // tslint:disable-next-line: prefer-for-of
        for (var i = 0; i < (data as any).data.length; i++) {


          flatData.push({
            _id: (data as any).data[i]._id,
            userFName: ((data as any).helper.users as any).filter((obj) => obj._id === (data as any).data[i].userId)[0].firstName,
            userLName: ((data as any).helper.users as any).filter((obj) => obj._id === (data as any).data[i].userId)[0].lastName,
            userCity: ((data as any).helper.users as any).filter((obj) => obj._id === (data as any).data[i].userId)[0].city,
            userGender: ((data as any).helper.users as any).filter((obj) => obj._id === (data as any).data[i].userId)[0].gender,
            userPhoneNumber: ((data as any).helper.users as any).filter((obj) => obj._id === (data as any).data[i].userId)[0].phoneNumber,
            userEmail: ((data as any).helper.users as any).filter((obj) => obj._id === (data as any).data[i].userId)[0].userEmail,
            checkoutDateMilli: (data as any).data[i].checkoutDateMilli,
            procedureMetaDataName: (data as any).data[i].procedureMetadata.name,
            procedurePrice: (data as any).data[i].procedure.price,
            hospitalName: (data as any).data[i].hospital.name,
            hospitalCity: (data as any).data[i].hospital.city

          });
      }
        (data as any).data = flatData;
        (data as any).widgetName = 'All Orders';
        this.data = data;
      });
  }


  handlePagination(event?: PageEvent) {
    this.getData(event.pageIndex, event.pageSize);
  }

}
