import { PhotoPickerDialogComponent } from './../photo-picker-dialog/photo-picker-dialog.component';
import { HospitalService } from './../services/hospital/hospital.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material';



@Component({
  selector: 'app-new-hospital',
  templateUrl: './new-hospital.component.html',
  styleUrls: ['./new-hospital.component.scss']
})
export class NewHospitalComponent implements OnInit {


  form: FormGroup;
  hTemp =  'https://easy-my-care-public-web.s3.ap-south-1.amazonaws.com/hospital/hospitalTemplate.jpg';
  images;

  constructor(private formBuilder: FormBuilder,  private _snackBar: MatSnackBar, private hospitalService: HospitalService,
              public dialog: MatDialog) {


                this.hospitalService.getImagesURL('hospital').subscribe(
                  response => {
                      this.images = response;
                  }
                );

  }

  ngOnInit() {

    this.form = this.formBuilder.group({
      hName: [null, [Validators.required]],
      hDescription: [null, [Validators.required]],
      hAddress: [null, [Validators.required]],
      hLongitude: [null, [Validators.required]],
      hLatitude: [null, [Validators.required]],
      hCity: [null, [Validators.required]]
    });

  }

  isFieldValid(field: string) {
    // console.log(field);
    return !this.form.get(field).valid && this.form.get(field).touched;
  }

  submitForm(message: string, action: string, event: Event) {

    if (!this.form.valid) {

      const snackBar = this._snackBar.open('Please fill the required information', '', {
        duration: 5000,
        panelClass: ['snackbar_no_action']
      });

      Object.keys(this.form.controls).forEach(field => {
        const control = this.form.get(field);
        // tslint:disable-next-line: no-unused-expression
        true && this.form.get(field).touched;
      });
    } else {

      const hospital = {
          name: this.form.get('hName').value,
          hospitalAddresses: this.form.get('hAddress').value,
          city: this.form.get('hCity').value,
          lon: this.form.get('hLongitude').value,
          lat: this.form.get('hLatitude').value,
          description: this.form.get('hDescription').value,
          hospitalImage: this.hTemp
        };



      this.hospitalService.addHospital(hospital).subscribe(
      (response) => {

        const snackBar = this._snackBar.open((response as any).info, '', {
          duration: 5000,
          panelClass: ['snackbar_no_action']
        });
        this.form.reset();
      }
      );
    }
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(PhotoPickerDialogComponent, {
      width: '600px',
      height: '400px',
      data: this.images
    });

    dialogRef.afterClosed().subscribe(result => {
      if ( result !== undefined ){
        this.hTemp = result;
      }
    });
  }

}
