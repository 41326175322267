import { LinkProcedureDialogComponent } from './../link-procedure-dialog/link-procedure-dialog.component';
import { DialogData, HospitalService } from './../services/hospital/hospital.service';
import { Component, OnInit } from '@angular/core';
import { HttpUrlEncodingCodec } from '@angular/common/http';
import { MatDialog } from '@angular/material';


@Component({
  selector: 'app-details-hospital',
  templateUrl: './details-hospital.component.html',
  styleUrls: ['./details-hospital.component.scss']
})
export class DetailsHospitalComponent implements OnInit {

  hospitals = [];
  procedures;
  codec = new HttpUrlEncodingCodec();

  constructor(private hospitalService: HospitalService, public dialog: MatDialog) { }

  ngOnInit() {



    this.hospitalService.hospitalList(0, 999999, 'DESC').subscribe(response => {
      this.hospitals = (response as any).hospital;
    });
  }

  urlEncode(val) {
    return this.codec.encodeValue(val);
  }


  OnChange($event, id){

    this.hospitalService.delete(id,$event.checked).subscribe(response => {

      if ( !response ){
        $event.source.toggle();
      }

    },
    error => {
      $event.source.toggle();
    });
   }

   openDialog(hId): void {

    const data: DialogData = {

      key: 'hospitalId',
      value: hId,
      pageSize: 10000,
      page: 0,
      sortBy: '_id',
      sortOrder: 'ASC'
    };

    this.hospitalService.getProcedures(data).subscribe( (response) => {
      this.procedures = (response as any).data;
      this.procedures.hId = hId;
      const dialogRef = this.dialog.open(LinkProcedureDialogComponent, {
        width: '800px',
        height: '600px',
        data: this.procedures
      });
      dialogRef.afterClosed().subscribe(result => {
        if ( result !== undefined ){
          // this.hTemp = result;
        }
      });

    });
  }

}
