import { ProcedureMetaDataService } from './../services/procedure-meta-data/procedure-meta-data.service';
import { PhotoPickerDialogComponent } from './../photo-picker-dialog/photo-picker-dialog.component';
import { HospitalService } from './../services/hospital/hospital.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-new-procedure',
  templateUrl: './new-procedure.component.html',
  styleUrls: ['./new-procedure.component.scss']
})
export class NewProcedureComponent implements OnInit {

  form: FormGroup;
  pTemp =  'https://easy-my-care-public-web.s3.ap-south-1.amazonaws.com/procedures/abpm_v2_r_1_1.jpg';
  images;

  constructor(private formBuilder: FormBuilder,  private _snackBar: MatSnackBar, private procedureMetaDataService: ProcedureMetaDataService,
              public dialog: MatDialog) {


                this.procedureMetaDataService.getImagesURL('procedures').subscribe(
                  response => {
                      this.images = response;
                  }
                );

  }

  ngOnInit() {

    this.form = this.formBuilder.group({
      pName: [null, [Validators.required]],
      pDescription: [null, [Validators.required]],
      pCategory: [null, [Validators.required]],
      pFrom: [null, [Validators.required]],
      pTo: [null, [Validators.required]],
      pTag: [null, [Validators.required]]
    });

  }

  isFieldValid(field: string) {
    // console.log(field);
    return !this.form.get(field).valid && this.form.get(field).touched;
  }

  submitForm(message: string, action: string, event: Event) {

    if (!this.form.valid) {

      const snackBar = this._snackBar.open('Please fill the required information', '', {
        duration: 5000,
        panelClass: ['snackbar_no_action']
      });

      Object.keys(this.form.controls).forEach(field => {
        const control = this.form.get(field);
        // tslint:disable-next-line: no-unused-expression
        true && this.form.get(field).touched;
      });
    } else {

      const procedure = {
          name: this.form.get('pName').value,
          category: this.form.get('pCategory').value,
          range: this.form.get('pFrom').value+'-'+this.form.get('pTo').value,
          image: this.pTemp,
          description: this.form.get('pDescription').value,
          tag: (this.form.get('pTag').value + '').split(',')
        };



      this.procedureMetaDataService.addProcedureMetaData(procedure).subscribe(
      (response) => {

        const snackBar = this._snackBar.open((response as any).info, '', {
          duration: 5000,
          panelClass: ['snackbar_no_action']
        });
        this.form.reset();
      }
      );
    }
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(PhotoPickerDialogComponent, {
      width: '600px',
      height: '400px',
      data: this.images
    });

    dialogRef.afterClosed().subscribe(result => {
      if ( result !== undefined ){
        this.pTemp = result;
      }
    });
  }

}
