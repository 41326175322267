import { ProcedureService, ProcedureDTO } from './../services/procedure/procedure.service';
import { Component, Inject, OnInit } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { HttpUrlEncodingCodec } from '@angular/common/http';
import * as $ from 'jquery';

@Component({
  selector: 'app-link-procedure-dialog',
  templateUrl: './link-procedure-dialog.component.html',
  styleUrls: ['./link-procedure-dialog.component.scss']
})
export class LinkProcedureDialogComponent implements OnInit {

  codec = new HttpUrlEncodingCodec();
  selected: any;
  hId;
  procedures;

  constructor(public dialogRef: MatDialogRef<LinkProcedureDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData,
              public procedureService: ProcedureService) {
    this.procedures = data;
    this.hId = this.procedures.hId;
  }


  ngOnInit() {
  }

  urlEncode(val) {
    return this.codec.encodeValue(val);

  }

  isActive(item) {
    return this.selected === item;
  }

  select(item) {
    this.selected = item;
  }

  add() {
    this.dialogRef.close('https://easy-my-care-public-web.s3.ap-south-1.amazonaws.com/' + this.urlEncode(this.selected) );
  }

  dblClickEdit(id, isNumber) {

    let current = $('#' + id).text();
    $('#' + id).html('<textarea class="form-control" id="newcont" rows="5">' + current + '</textarea>');

    if ( isNumber ) {
      $('#' + id).keypress(function(e) {
        let a = [];
        let k = e.which;
        for (let i = 48; i < 58; i++) {
          a.push(i);
        }
        if (!(a.indexOf(k) >= 0)) {
          e.preventDefault();
        }
      });
  }
    $('#newcont').focus();
    $('#newcont').focus(function() {
          console.log('in');
      }).blur(function() {
           let newcont = $('#newcont').val();
           $('#' + id).text(newcont);
      });
  }

  getInnerHTML( id ){
    return $('#' + id).text();
  }

  getPrice( procedure ) {

    if ( procedure.price === undefined || procedure.price === null ) {
      return procedure.procedureMetadata.range.split('-', 1);
    }
    return procedure.price;
  }

  getDescription( procedure ) {

    if ( procedure.description === undefined || procedure.description === null ) {
      return procedure.procedureMetadata.description;
    }
    return procedure.description;
  }

  OnChange($event, procedure) {

    const details: ProcedureDTO = {
      hospitalId: this.hId,
      procedureMetadataId: procedure.procedureMetadata._id,
      price: this.getInnerHTML('price_' + procedure.procedureMetadata._id),
      description: this.getInnerHTML('des_' + procedure.procedureMetadata._id),
      enabled: $event.checked
    };


    this.procedureService.linkProcedure(details).subscribe(response => {

      if ( !response ) {
        $event.source.toggle();
      }

    },
    error => {
      $event.source.toggle();
    });
   }

}

export interface DialogData {
  data: [];
}

