import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConstantsService } from '../../common/services/constants.service';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  constructor(private httpClient: HttpClient) { }

  public allUsers(page, row) {
    // tslint:disable-next-line: max-line-length
    return this.httpClient.get(ConstantsService.baseEndpoint + 'analytics/users' + '?property=createdTimeMilli&order=DESC&page=' + page + '&rows=' + row,
    { withCredentials: true } );

  }

  public getFromT(T, order, row, agg) {
    // tslint:disable-next-line: max-line-length
    return this.httpClient.get(ConstantsService.baseEndpoint + 'analytics/getFromT' + '?T=' + T + '&order=' + order + '&row=' + row + '&agg=' + agg,
    { withCredentials: true } );
  }


  public getCountFrom(T) {
    // tslint:disable-next-line: max-line-length
    return this.httpClient.get(ConstantsService.baseEndpoint + 'analytics/getCountFrom' + '?T=' + T + '&includeDeleted=' + false ,
    { withCredentials: true } );
  }

  public allOrders(page, row) {
    // tslint:disable-next-line: max-line-length
    return this.httpClient.get(ConstantsService.baseEndpoint + 'analytics/orders' + '?property=_id&order=DESC&page=' + page + '&rows=' + row,
    { withCredentials: true } );
  }

}
