import { DetailsProcedureComponent } from './details-procedure/details-procedure.component';
import { NewProcedureComponent } from './new-procedure/new-procedure.component';
import { LiveComponent } from './live/live.component';
import { DetailsHospitalComponent } from './details-hospital/details-hospital.component';
import { NewHospitalComponent } from './new-hospital/new-hospital.component';
import { OrderAnalyticsComponent } from './order-analytics/order-analytics.component';
import { UserAnalyticsComponent } from './user-analytics/user-analytics.component';
import { HomeComponent } from './home/home.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { LoginGuard } from './login.guard';


const routes: Routes = [
  { path: 'login', component: LoginComponent},
  {path: '', component: HomeComponent, canActivate: [LoginGuard]},
  {path: 'live', component: LiveComponent},
  {path: 'analytics/user', component: UserAnalyticsComponent, canActivate: [LoginGuard]},
  {path: 'analytics/order', component: OrderAnalyticsComponent, canActivate: [LoginGuard]},
  {path: 'hospital/new', component: NewHospitalComponent, canActivate: [LoginGuard]},
  {path: 'hospital/details', component: DetailsHospitalComponent, canActivate: [LoginGuard]},
  {path: 'procedure/new', component: NewProcedureComponent, canActivate: [LoginGuard]},
  {path: 'procedure/details', component: DetailsProcedureComponent, canActivate: [LoginGuard]},
  {path: '**', redirectTo: ''}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
