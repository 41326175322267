import { AnalyticsService } from './../services/analytics/analytics.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {


  procedure;
  user;
  metadata;
  hospital;
  order;
  campaign;

  constructor(private analyticsService: AnalyticsService) { }

  ngOnInit() {
    this.getTotalProcedure('PROCEDURE');
    this.getTotalUser('USER');
    this.getTotalProcedureMetaData('PROCEDURE_METADATA');
    this.getTotalHospital('HOSPITAL');
    this.getTotalOrder('ORDERS');
    this.getTotalCampaign('CAMPAIGN_RESPONSE');

  }

  getTotalProcedure(T) {
    this.analyticsService.getCountFrom(T).subscribe(response => {
      let m = {};
      (m as any).data = response;
      (m as any).widgetName = 'Procedures Listed';
      this.procedure = m;
    });
  }

  getTotalUser(T) {
    this.analyticsService.getCountFrom(T).subscribe(response => {
      let m = {};
      (m as any).data = response;
      (m as any).widgetName = 'All Users';
      this.user = m;
    });
  }

  getTotalProcedureMetaData(T) {
    this.analyticsService.getCountFrom(T).subscribe(response => {
      let m = {};
      (m as any).data = response;
      (m as any).widgetName = 'Procedures';
      this.metadata = m;
    });
  }

  getTotalHospital(T) {
    this.analyticsService.getCountFrom(T).subscribe(response => {
      let m = {};
      (m as any).data = response;
      (m as any).widgetName = 'Hospitals';
      this.hospital = m;
    });
  }


  getTotalOrder(T) {
    this.analyticsService.getCountFrom(T).subscribe(response => {
      let m = {};
      (m as any).data = response;
      (m as any).widgetName = 'All Orders';
      this.order = m;
    });
  }

  getTotalCampaign(T) {
    this.analyticsService.getCountFrom(T).subscribe(response => {
      let m = {};
      (m as any).data = response;
      (m as any).widgetName = 'Campaign Response';
      this.campaign = m;
    });
  }



}
