import { MatSnackBar } from '@angular/material/snack-bar';
import { LoginService } from './../services/login/login.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;

  constructor(
      private formBuilder: FormBuilder, private loginService: LoginService, private snackBar: MatSnackBar,
      private router: Router) {
  }

  ngOnInit() {
      this.loginForm = this.formBuilder.group({
          email: ['', Validators.required],
          password: ['', Validators.required]
      });

  }

  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }

  openSnackBar(message: string, action: string) {

    if ( action === null ) {
      const snackBar = this.snackBar.open(message, null, {
        duration: 5000,
        panelClass: ['snackbar_no_action']
      });
    } else {
      this.snackBar.open(message, action, {
        duration: 5000,
      });
    }
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
        return;
    }

    this.loading = true;
    this.loginService.login(this.f.email.value, this.f.password.value).subscribe(
      data => {
        console.log(data);
        this.router.navigateByUrl('');
      },
      error => {
        this.openSnackBar('Invalid Creds', null);
        this.submitted = false;
        this.loading = false;
      });
    }
}
